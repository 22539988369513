import http from '@/http';
import globalHelper from "@/helpers/global.helper";

export default {
    state: {
        model: {
            first_name: '',
            last_name: '',
            address1: '',
            suburb: '',
            state: '',
            postcode: '',
            country: '',
            email: '',
            phone: '',
            source: 'Membership Form',
            membership_type: 'N',
            payment_type: 'CC',
            payment_method_id: null,
            receipt_name: '',
            details_type: 'personal',
            frequency: 'O',
            recaptcha: null
        },
        donatedModel: {},
        paymentModel: {
            stripeErr: '',
            cardValidate: false,
            cardNumber: null,
            auBankAccount: null,
        },
        processLoading: false
    },
    getters: {
        model: state => state.model,
        donatedModel: state => state.donatedModel,
        paymentModel: state => state.paymentModel,
        processLoading: state => state.processLoading,
    },
    mutations: {
        setLoading(state, payload) {
            state.processLoading = payload;
        },
        updateModel(state, payload) {
            state.processLoading = false;
            state.model = globalHelper.updatePropertyObj(state.model, payload);
        },
        updateDonateModel(state, payload) {
            state.processLoading = false;
            state.donatedModel = payload;
        }, 
        updatePaymentModel(state, payload) {
            state.paymentModel = globalHelper.updatePropertyObj(state.paymentModel, payload);
        },
        resetModel(state, payload) {
            state.donatedModel = {};
            state.paymentModel = globalHelper.resetObjValue(state.paymentModel, {
                cardValidate: false,
                cardNumber: null,
                auBankAccount: null,
            });
            state.processLoading = false;
            state.model = globalHelper.resetObjValue(state.model, {
                amount: 400,
                frequency: 'O',
                payment_type: 'CC',
                payment_method_id: null,
                details_type: 'personal'
            });
        }
    },
    actions: {
        createPaymentIntent({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post(`paymentmethods/payment_intent`, data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donatePaymentAmount({ commit }, data) {
            commit("setLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('donates', data).then(response => {
                    commit("toggleAppLoading");
                    const resData = response.data.data || null;;
                    if (resData && resData.status !== 0) {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: "Donation Processed Successfully",
                            color: "success",
                        });
                        commit("updateDonateModel", resData);
                    } else {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: resData && resData.failed_reason ? resData.failed_reason : 'Error!',
                            color: "error",
                        });
                    }
                    commit("setLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setLoading", false);
                    commit("toggleAppLoading");
                    rej(error)
                });
            });
        }
    },
};
