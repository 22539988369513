import http from '@/http';

export default {
  state: {
    themeModel: {},
    themeLoading: false,
    themeRecordType: null
  },
  getters: {
    themeApiModel: state => state.themeModel,
    themeLoading: state => state.themeLoading,
    themeRecordType: state => state.themeRecordType
  },
  mutations: {
    setThemeLoading(state, payload) {
      state.themeLoading = payload;
    },
    setThemeModel(state, payload) {
      state.themeLoading = false;
      state.themeModel = payload;
    },
    setThemeRecordType(state, payload) {
      state.themeRecordType = payload;
    }
  },
  actions: {
    getThemeModel({ commit }) {
      commit("setThemeLoading", true);
      return new Promise((res, rej) => {
        http('donate').get('options/MEMBERSHIP_THEME').then(response => {
          const respData = response.data.data || null;
          if (respData && respData.option_value) {
            commit("setThemeModel", respData.option_value);
          }
          commit("setThemeLoading", false);
          res(response);
        }).catch((error) => {
          commit("setThemeLoading", false);
          rej(error)
        });
      });
    },
    getThemeType({ commit }) {
      return new Promise((res, rej) => {
        http('donate').get('options/MEMBERSHIP_RECORD_TYPE').then(response => {
          const respData = response.data.data || null;
          if (respData && respData.option_value) {
            commit("setThemeRecordType", respData.option_value);
          }
          res(response);
        }).catch((error) => {
          rej(error)
        });
      });
    },
  },
};
